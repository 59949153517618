//
// Component: Button
//
.btn {
    &.disabled,
    &:disabled {
        cursor: not-allowed;
    }
    // Flat buttons
    &.btn-flat {
        @include border-radius(0);
        border-width: 1px;
        box-shadow: none;
    }
    // input file btn
    &.btn-file {
        overflow: hidden;
        position: relative;
        >input[type='file'] {
            background: $white;
            cursor: inherit;
            display: block;
            font-size: 100px;
            min-height: 100%;
            min-width: 100%;
            opacity: 0;
            outline: none;
            position: absolute;
            right: 0;
            text-align: right;
            top: 0;
        }
    }
    .text-sm & {
        font-size: $font-size-sm !important;
    }
}

// Button color variations
.btn-default {
    background-color: $button-default-background-color;
    border-color: $button-default-border-color;
    color: $button-default-color;
    &:hover,
    &:active,
    &.hover {
        background-color: darken($button-default-background-color, 5%);
        color: darken($button-default-color, 10%);
    }
}

// Application buttons
.btn-app {
    @include border-radius(3px);
    background-color: $button-default-background-color;
    border: 1px solid $button-default-border-color;
    color: $gray-600;
    font-size: 12px;
    height: 60px;
    margin: 0 0 10px 10px;
    min-width: 80px;
    padding: 15px 5px;
    position: relative;
    text-align: center;
    // Icons within the btn
    >.fa,
    >.fas,
    >.far,
    >.fab,
    >.glyphicon,
    >.ion {
        display: block;
        font-size: 20px;
    }
    &:hover {
        background: $button-default-background-color;
        border-color: darken($button-default-border-color, 20%);
        color: $button-default-color;
    }
    &:active,
    &:focus {
        @include box-shadow(inset 0 3px 5px rgba($black, 0.125));
    }
    // The badge
    >.badge {
        font-size: 10px;
        font-weight: 400;
        position: absolute;
        right: -10px;
        top: -3px;
    }
}

// Extra Button Size
.btn-xs {
    @include button-size($button-padding-y-xs, $button-padding-x-xs, $button-font-size-xs, $button-line-height-xs, $button-border-radius-xs);
}

// Rahul-Gupta Custom
@media (max-width: 767px) {
    .btn {
        @include button-size($button-padding-y-sm, $button-padding-x-sm, $button-font-size-sm, $button-line-height-sm, $button-border-radius-sm);
    }
}